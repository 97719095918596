<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ $t("manage_permission") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-dialog v-model="dialog" max-width="500px" persistent>
                    <v-card>
                      <v-card-title>{{ $t("edit_permission") }}</v-card-title>

                      <v-icon class="btn_close" @click="dialog = false"
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 250px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-row>
                            <v-col sm="12" cols="12" class>
                              <label class="label">{{ $t("name_en") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_en"
                                placeholder="e.g Manage User"
                                append-icon="fa-id-card"
                                precision="3"
                                outlined
                                required
                              />
                              <label class="label">{{ $t("name_kh") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_kh"
                                placeholder="e.g គ្រប់គ្រងអតិថិជន"
                                append-icon="fa-envelope"
                                precision="3"
                                outlined
                                required
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          @click.prevent="onSaveClose()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listPermissions"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("icon") }}</th>
                              <th>{{ $t("url_ref") }}</th>
                              <th>{{ $t("ordering") }}</th>
                              <th class="text-center">{{ $t("action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody style="white-space: nowrap">
                            <tr
                              v-for="(perm, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ perm.name_en }}</td>
                              <td>{{ perm.name_kh }}</td>
                              <td>
                                <v-icon>
                                  {{ perm.icon }}
                                </v-icon>
                              </td>
                              <td>{{ perm.url_ref }}</td>
                              <td>{{ perm.ordering }}</td>

                              <td class="text-center">
                                <v-btn
                                  @click="onEditItem(perm)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="primary"
                                >
                                  <v-icon size="15" dark>fa fa-pen</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import {
  getPermission,
  createPermission,
  updatePermission,
  deletePermission,
} from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      isLoading: false,
      myLoading: false,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "icon", value: "icon" },
        { text: "url_ref", value: "url_ref" },
        { text: "ordering", value: "ordering" },
        { text: "active_class", value: "active_class" },
      ],

      listPermissions: [],
      dialog: false,
      name_en: "",
      name_kh: "",
      permId: "",
      icon: "",
      token: store.getters.getToken,
      url_ref: "",
      ordering: "",
      active_class: "",
    };
  },
  methods: {
    onEditItem(item) {
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.icon = item.icon;
      this.url_ref = item.url_ref;
      this.permId = item.id;
      this.ordering = item.ordering;
      this.active_class = item.active_class;
      this.dialog = true;
    },
    // Clear Data After User click cancel or dialoge is false
    clear() {
      this.name_en = "";
      this.name_kh = "";
      this.icon = "";
      this.url_ref = "";
      this.ordering = "";
      this.active_class = "";
      this.id = "";
      this.permId = "";
    },

    //Create User Function
    async onSaveClose() {
      let data = {
        name_kh: this.name_kh,
        name_en: this.name_en,
        icon: this.icon,
        ordering: this.ordering,
        active_class: this.active_class,
        url_ref: this.url_ref,
      };
      if (this.permId) {
        if (this.permId !== "") {
          try {
            await updatePermission(this.permId, data);
            this.loadPermission();
            this.clear();
          } catch (e) {
            this.snackbar = {
              message: e,
              color: "error",
              show: true,
            };
          }
        }
      } else {
        try {
          await createPermission(data);
          this.loadPermission();
          this.clear();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      window.console.log(this.permId, "roleID");
      this.dialog = false;
    },
    async loadPermission() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getPermission(this.token);
        this.listPermissions = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deleterperm(perm) {
      const index = this.listPermissions.indexOf(perm);
      this.deletItems = perm;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listPermissions.splice(index, 1);
        await this.deletePerm();
      }
    },
    async deletePerm() {
      try {
        await deletePermission(this.deletItems.id);
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.loadPermission();
  },
  components: {
    Loading,
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}
.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}
.btn_create_new {
  color: #3316f2 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
